<template>
  <div>
    <el-table
      :data="tableData"
      border
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      :row-class-name="tableRowClassName"
      :span-method="arraySpanMethod"
    >
      <el-table-column label="序号" prop="id" width="80"></el-table-column>
      <el-table-column align="center" prop="contract_and_project" label="项目或工地" width="200"> </el-table-column>
      <el-table-column align="center" prop="cangku_name" label="出库库房" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiao_and_cailiaoguige" label="材料名称" width="120"> </el-table-column>
      <el-table-column align="center" prop="amount" label="出库数量" width="100"> </el-table-column>
      <el-table-column align="center" prop="date" label="出库时间" width="100"> </el-table-column>
      <el-table-column align="center" prop="remark" label="备注信息" width="250"> </el-table-column>
      <el-table-column align="center" label="出库单据" width="120">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="handlePhotos(scope.row)">出库单据</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="接收单据" width="120">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="canShowchukuPhotos(scope.row)" @click="handlereceivePhotos(scope.row)">接收单据</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="received_date" label="接收日期" width="100"> </el-table-column>
      <el-table-column align="center" prop="added" label="添加" width="200"> </el-table-column>
      <el-table-column align="center" prop="wuzi_shenhe_person" label="物资审核" width="80"> </el-table-column>
      <el-table-column align="center" label="管理" width="200">
        <template slot-scope="scope" v-if="!(scope.row.type === '2' || scope.row.type === '3')">
          <el-button size="mini" type="warning" @click="handleReceive(scope.row)" v-if="canReceive(scope.row)">接收</el-button>
          <el-button type="warning" size="mini" @click="handleWuzi_shenhe(scope.row)" v-if="!scope.row.wuzi_shenhe && userType.code === '4'"
            >出库物资审核</el-button
          >
          <el-button type="warning" size="mini" @click="handleWuzi_shenheJieshou(scope.row)" v-if="canWuzishenheJieshou(scope.row)">接收物资审核</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  data() {
    return {
      pagesize: 20
    }
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.kufangchuku_del) {
          if (this.userType.id === 1) return true
          else {
            console.log('added:', row.added)
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            // console.log('addedtime:', added_person_time)
            // console.log('now:', now)
            // console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    },
    canReceive() {
      return function(row) {
        console.log('usertype_code:', this.userType.code)
        console.log('is_onroad:', row.is_onroad)
        if (row.bendikufang === '2' && row.is_onroad === true && row.wuzi_shenhe) return true
        else return false
      }
    },

    canWuzishenheJieshou() {
      return function(row) {
        console.log('wuzishenhejieshou', row.wuzi_shenheJieshou)
        if (!row.wuzi_shenhe_jieshou && row.bendikufang === '2' && row.wuzi_shenhe && this.userType.code === '4') return true
        else return false
      }
    },
    canShowchukuPhotos() {
      return function(row) {
        if (row.bendikufang === '2' && row.is_ingongdi === true) return true
        else return false
      }
    }
  },
  methods: {
    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },
    //显示出库图片
    handlePhotos(row) {
      this.$emit('showPhotos', row)
    },
    //接收按钮响应事件
    handleReceive(row) {
      this.$emit('doReceive', row)
    },
    //显示接收图片
    handlereceivePhotos(row) {
      this.$emit('showReceivePhotos', row)
    },
    //出库物资审核
    handleWuzi_shenhe(row) {
      this.$emit('Wuzi_shenhe', row)
    },
    //接收物资审核
    handleWuzi_shenheJieshou(row) {
      this.$emit('Wuzi_shenheJieshou', row)
    },

    //设置特殊行（如已结单）的状态
    //设置小计和合计行的状态
    tableRowClassName({ row, rowIndex }) {
      // console.log(row)
      // console.log(rowIndex)
      if (row.type === '2') {
        return 'xiaoji-row'
      } else if (row.type === '3') {
        return 'heji-row'
      } else if (rowIndex % 2 === 0) {
        return 'stripe-row'
      }
      return 'normal-row'
    },
    //小计和合计列合并
    arraySpanMethod({ row, columnIndex }) {
      // console.log(row)
      if (row.type === '2' || row.type === '3') {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 3]
        } else if (columnIndex === 2) {
          return [0, 0]
        }
      }
    }
    //合计
    // getSum(param) {
    //   const { columns, data } = param
    //   const sums = []
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = '合计'
    //       return
    //     }
    //     const values = data.map(item => Number(item[column.property]))
    //     // column.property === 'bmoney'  bmoney 是你要总计table中的那一列的 prop值
    //     if (column.property === 'amount') {
    //       sums[index] = values.reduce((prev, curr) => {
    //         const value = Number(curr)
    //         if (!isNaN(value)) {
    //           return prev + curr
    //         } else {
    //           return prev
    //         }
    //       }, 0)
    //       // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
    //       // sums[index] += sums[index];sums[index];
    //     } else {
    //       sums[index] = '--'
    //     }
    //   })
    //   return sums
    // }
  }
}
</script>
